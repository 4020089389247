import React from "react";
import { Badge } from "react-bootstrap";
import styled from "styled-components";

// 1	Ambulatory Service
// 2	Behaviour
// 3	Cardiology
// 4	Dermatology
// 5	Diagnostic Imaging
// 6	ECC - Emergency
// 7	Exotics
// 8	Internal Medicine
// 9	Neurology
// 10	Oncology
// 11	OOH - Emergency
// 12	Ophthalmology
// 13	Surgery
// 14	Nutrition
// 15	Video Vets Now
// 19 Orhtopaedics
// 20 Soft Tissue
// Red – OOH
// Pink – ECC
// Yellow – Oncology
// Green – Medicine
// Blue – Surgery
// Purple – Diagnostic Imaging

function DisciplineTag(props) {
  switch (props.DisciplineId) {
    case 2: //Behaviour
      return (
        <Badge style={{ fontSize: 12 }} variant="secondary">
          {props.DisciplineName}
        </Badge>
      );
    case 3: //Cardiology
      return (
        <Badge style={{ fontSize: 12 }} variant="secondary">
          {props.DisciplineName}
        </Badge>
      );
    case 4: //Dermatology
      return (
        <Badge style={{ fontSize: 12 }} variant="secondary">
          {props.DisciplineName}
        </Badge>
      );
    case 5: //Diagnostic Imaging
      return (
        <Badge
          style={{ fontSize: 12, backgroundColor: "#9370db" }}
          variant="secondary"
        >
          {props.DisciplineName}
        </Badge>
      );
    case 6: //ECC - Emergency
      return (
        <Badge
          style={{ fontSize: 12, backgroundColor: "#ff69b4" }}
          variant="secondary"
        >
          ECC
        </Badge>
      );
    case 8: //Internal Medicine
      return (
        <Badge style={{ fontSize: 12 }} variant="success">
          {props.DisciplineName}
        </Badge>
      );
    case 9: //Neurology
      return (
        <Badge style={{ fontSize: 12 }} variant="secondary">
          {props.DisciplineName}
        </Badge>
      );
    case 10: //Oncology
      return (
        <Badge style={{ fontSize: 12 }} variant="warning">
          {props.DisciplineName}
        </Badge>
      );
    case 11: //OOH - Emergency
      return (
        <Badge
          style={{ fontSize: 12, backgroundColor: "#8d021f" }}
          variant="danger"
        >
          OOH
        </Badge>
      );
    case 12: //Opthamology
      return (
        <Badge style={{ fontSize: 12 }} variant="secondary">
          {props.DisciplineName}
        </Badge>
      );
    case 13: //Surgery
      return (
        <Badge
          style={{ fontSize: 12, backgroundColor: "#23b8ff" }}
          variant="primary"
        >
          {props.DisciplineName}
        </Badge>
      );
    case 14: //Nutrition
      return (
        <Badge style={{ fontSize: 12 }} variant="secondary">
          {props.DisciplineName}
        </Badge>
      );
    case 19: //Orthopaedics
      return (
        <Badge style={{ fontSize: 12, backgroundColor: "#5eead4" }}>
          {props.DisciplineName}
        </Badge>
      );
    case 20: //Soft Tissue
      return (
        <Badge style={{ fontSize: 12, backgroundColor: "#22d3ee " }}>
          {props.DisciplineName}
        </Badge>
      );
    default:
      return (
        <Badge style={{ fontSize: 12 }} variant="secondary">
          {props.DisciplineName}
        </Badge>
      );
  }
}
export default DisciplineTag;
