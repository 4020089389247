import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Col, Card, Badge, Collapse } from "react-bootstrap";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
// import {Collapse} from 'react-collapse';
import Cell from "../Cell";

const ColumnContainer = styled.div`
  position: relative;
  border: ${(props) =>
    props.isEmpty ? "none" : "1px solid rgba(0, 0, 0, 0.125)"};
  border-radius: 0.25rem;
  margin-bottom: ${(props) => (props.isEmpty ? "none" : "15px")};
`;

function ChildColumn(props) {
  const [data, setData] = useState("");
  const [open, setOpen] = useState(props.openDefault);
  return (
    <ColumnContainer isEmpty={!props.data.length}>
      {props.data.length ? (
        <>
          <Card
            onClick={() => setOpen(!open)}
            aria-controls="collapse-content"
            style={{ cursor: "pointer", height: 60 }}
          >
            <div
              className="font-weight-bold bg-light"
              style={{
                fontSize: 15,
                display: "flex",
                alignItems: "center",
                padding: 10,
                height: "100%",
              }}
            >
              <span style={{ width: "75%" }}>{props.title}</span>
              {props.data && (
                <>
                  <Badge
                    style={{
                      fontSize: 14,
                      position: "absolute",
                      bottom: 18,
                      right: 25,
                    }}
                    variant="info"
                  >
                    {props.data.length}
                  </Badge>
                </>
              )}
              {props.data && props.data.length && open ? (
                <ChevronUp
                  style={{ position: "absolute", bottom: 20, right: 5 }}
                />
              ) : null}
              {props.data && props.data.length && !open ? (
                <ChevronDown
                  style={{ position: "absolute", bottom: 20, right: 5 }}
                />
              ) : null}
            </div>
          </Card>

          <div
            style={{
              maxHeight: props.width > 576 ? "55vh" : "none",
              overflowX: "auto",
              position: "relative",
            }}
          >
            <Collapse in={open}>
              <div key={props.title} id="collapse-content">
                {props.data &&
                  props.data.map((d) => (
                    <Cell
                      key={d.CaseId}
                      item={d}
                      handleUpdateCell={props.handleUpdate}
                      contextMenuStatuses={props.contextMenuStatuses}
                    ></Cell>
                  ))}
              </div>
            </Collapse>
          </div>
        </>
      ) : null}
    </ColumnContainer>
  );
}
export default ChildColumn;
