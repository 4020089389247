import React, { useState, useEffect, useRef } from "react";
import { Row, Col, OverlayTrigger, Popover, Badge } from "react-bootstrap";
import ContextMenu from "../ContextMenu";
import DisciplineTag from "./DisciplineTag";
import { ContextMenuTrigger } from "react-contextmenu";
import { isToday, ragStatusColor, timeLabel } from "../../../Utils";
import styled from "styled-components";

const CaseContainer = styled.div`
   {
    border: 1px solid gainsboro;
    background: #dbecf2;
    border-radius: 0.25rem;
    font-size: 11px;
    margin-top: 5px;
    width: 97%;
    padding: 0 20px;
    user-select: none;
    transition: 0.8s;
    cursor: pointer;
    @media (min-width: 1500px) {
      font-size: 13px;
    }
  }
  &&:focus {
    border: 1px solid black;
  }
`;
const Bolder = styled.div`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${(props) => props.paddingright && "8px;"};
`;
const Ragstatus = styled.div`
  & {
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 25px;
    line-height: 25px;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid #979797;
    background: ${(props) => props.color || "#dbecf2"};
    z-index: 99;
    @media (min-width: 1300px) {
      line-height: 30px;
      width: 30px;
      font-size: 24px;
      bottom: -4px;
      right: 0;
    }
    @media (min-width: 1700px) {
      line-height: 40px;
      width: 40px;
      font-size: 28px;
      bottom: -15px;
      right: 0;
    }
  }
`;

function Cell(props) {
  const [updated, setUpdated] = useState("");
  const isInitialMount = useRef(true);
  const target = useRef(null);
  const showEmergencyTag =
    props.item.IsEmergency &&
    props.item.CaseStatus.trim() === "Travelling To Clinic";
  const showDisiplineTag =
    props.item.DisciplineId && [2, 173, 185].includes(props.item.ClinicId); //Only show disipline in hospital sites (glasgow, manchester, Blaise)

  useEffect(() => {
    if (isInitialMount.current && !props.item.uiUpdate) {
      isInitialMount.current = false;
    } else {
      setUpdated("pulse-success");
      setTimeout(() => {
        setUpdated("");
      }, 3000);
    }
  }, [props.item.uiUpdate]);
  return (
    <CaseContainer
      className={updated + " container"}
      onDoubleClick={() =>
        window.parent.postMessage(
          {
            message: "openCaseDetail",
            CaseId: props.item.CaseId,
          },
          "*"
        )
      }
    >
      <ContextMenuTrigger id={props.item.CaseId + "-contextmenu"}>
        <Row>
          <Col className="p-0">
            <Bolder>{props.item.ClientNameWithTitle} </Bolder>
            {showDisiplineTag && (
              <>
                <DisciplineTag
                  DisciplineId={props.item.DisciplineId}
                  DisciplineName={props.item.Discipline}
                />{" "}
              </>
            )}

            {showEmergencyTag && (
              <>
                <Badge style={{ fontSize: 12 }} variant="danger">
                  Emergency
                </Badge>{" "}
              </>
            )}
            {props.item.IsPdsa ? (
              <Badge style={{ fontSize: 12 }} variant="primary">
                PDSA
              </Badge>
            ) : null}
          </Col>
          <Col xs="auto" className="p-0">
            <Bolder paddingright>#{props.item.CaseId}</Bolder>
          </Col>
        </Row>
        <Row>
          <Col
            className="p-0"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "85%",
            }}
          >
            <strong>{props.item.AnimalName}</strong> ({props.item.Species})
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <div>{props.item.Symptom}</div>
          </Col>
          {props.item.CaseStatus.trim() === "Awaiting Consultation" &&
            props.item.ragStatus && (
              <Col className="p-0">
                {props && props.item.ragCreated && (
                  <OverlayTrigger
                    trigger="hover"
                    placement="left"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Content>
                          Rag status set{" "}
                          <strong>
                            {(isToday(props.item.ragCreated)
                              ? "today"
                              : new Date(
                                  props.item.ragCreated
                                ).toLocaleDateString()) +
                              " @ " +
                              new Date(
                                props.item.ragCreated
                              ).toLocaleTimeString()}
                          </strong>{" "}
                          by <strong>{props.item.ragCreatedBy}</strong>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <Ragstatus
                      // className={!props.item.ragStatus ? "pulse" : ""}
                      ref={target}
                      color={ragStatusColor(props.item.ragStatus)}
                    >
                      {props.item.ragStatus || "NA"}
                    </Ragstatus>
                  </OverlayTrigger>
                )}
              </Col>
            )}
        </Row>
        <Row>
          <Col className="p-0">
            <Bolder>{timeLabel(props.item)}</Bolder>
          </Col>
        </Row>
      </ContextMenuTrigger>
      <ContextMenu
        CurrentStatus={props.item.CaseStatus}
        CaseId={props.item.CaseId}
        handleUpdateContext={props.handleUpdateCell}
        showRag={props.item.CaseStatus.trim() === "Awaiting Consultation"}
        caseStatusList={props.contextMenuStatuses}
      ></ContextMenu>
    </CaseContainer>
  );
}
export default Cell;
