import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Col,
  Card,
  Badge,
  Collapse,
  ProgressBar,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { ChevronUp, ChevronDown, CircleFill } from "react-bootstrap-icons";
// import {Collapse} from 'react-collapse';
import Cell from "../Cell";
import ButtonFilterTriaged from "./ButtonFilterTriaged";
import { percentage } from "../../../Utils";
import Skeleton from "react-loading-skeleton";

const ColumnContainer = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
`;
const ProgressContainer = styled.div`
  position: absolute;
  top: 40px;
  width: 100%;
  padding: 0 8px;
  z-index: 99;
  @media (min-width: 576px) {
    bottom: -20px;
    top: unset;
  }
`;

const sortOrder = ["G", "A", "R"];

function Column(props) {
  const [open, setOpen] = useState(false);
  const [notTriaged, setNotTriaged] = useState(0);
  const [triagedCount, setTriagedCount] = useState(null);
  const [triagedOnly, setTriagedOnly] = useState(false);

  useEffect(() => {
    function countToBeTriaged() {
      if (props.data && props.title === "Awaiting Consultation") {
        const cases = props.data.filter(
          (x) => x.CaseStatus.trim() === "Awaiting Consultation" && !x.ragStatus
        );
        setNotTriaged(cases.length);

        setTriagedCount([
          props.data.filter((x) => x.ragStatus === "R").length,
          props.data.filter((x) => x.ragStatus === "A").length,
          props.data.filter((x) => x.ragStatus === "G").length,
          props.data.length,
        ]);
      }
    }
    countToBeTriaged();
  }, [props.data]);

  return (
    <Col className="p-2" sm="6" lg="2" xl="2">
      <ColumnContainer>
        <Card
          data-tut={props.isFirstColumn && "reactour__columns"}
          onClick={() => setOpen(!open)}
          aria-controls="collapse-content"
          style={{ cursor: "pointer", height: 60 }}
        >
          <div
            className="font-weight-bold bg-light"
            style={{
              fontSize: 15,
              display: "flex",
              alignItems: "center",
              padding: 10,
              height: "100%",
            }}
          >
            <span style={{ width: "75%" }}>{props.title}</span>
            <>
              <Badge
                data-tut={props.isFirstColumn && "reactour__badge"}
                style={{
                  fontSize: 14,
                  position: "absolute",
                  bottom: 18,
                  right: 25,
                }}
                variant="info"
              >
                {props.data ? props.data.length : 0}
              </Badge>
            </>
            {props.data && props.data.length && open ? (
              <ChevronDown
                style={{ position: "absolute", bottom: 20, right: 5 }}
              />
            ) : null}
            {props.data && props.data.length && !open ? (
              <ChevronUp
                style={{ position: "absolute", bottom: 20, right: 5 }}
              />
            ) : null}
          </div>
        </Card>
        {props.title === "Awaiting Consultation" && triagedCount && (
          <OverlayTrigger
            trigger="hover"
            placement="top"
            overlay={
              <Popover id="popover-basic">
                <Popover.Content>
                  <strong>Triaged counts:</strong>
                  <br />
                  {triagedCount[0]} <CircleFill color="red" /> Red
                  <br />
                  {triagedCount[1]} <CircleFill color="orange" /> Amber
                  <br />
                  {triagedCount[2]} <CircleFill color="green" /> Green
                  <br />
                  {notTriaged} <CircleFill color="grey" /> Not set
                </Popover.Content>
              </Popover>
            }
          >
            <ProgressContainer>
              <ProgressBar data-tut="reactour__progressBar">
                <ProgressBar
                  striped
                  variant="danger"
                  now={percentage(triagedCount[0], triagedCount[3])}
                  key={3}
                />
                <ProgressBar
                  variant="warning"
                  now={percentage(triagedCount[1], triagedCount[3])}
                  key={2}
                />
                <ProgressBar
                  striped
                  variant="success"
                  now={percentage(triagedCount[2], triagedCount[3])}
                  key={1}
                />
              </ProgressBar>
            </ProgressContainer>
          </OverlayTrigger>
        )}
        <div
          style={{
            maxHeight: props.width > 576 ? "calc(81vh - 30px)" : "none",
            overflowX: "auto",
            position: "relative",
          }}
        >
          <Collapse in={!open}>
            <div key={props.title} id="collapse-content">
              {props.data &&
                props.title === "Awaiting Consultation" && (
                  <>
                    <div
                      className="sticky-top"
                      style={{ marginTop: 5, display: "flex" }}
                    >
                      <ButtonFilterTriaged
                        triagedOnly={triagedOnly}
                        setTriagedOnly={setTriagedOnly}
                        notTriaged={notTriaged}
                      />
                    </div>
                  </>
                )}
              {(props.data &&
                props.data
                  .filter(
                    (x) =>
                      x.CaseStatus.trim() !== "Awaiting Consultation" ||
                      (triagedOnly && !x.ragStatus) ||
                      notTriaged === 0 ||
                      !triagedOnly //Logic for triage button to filter triaged cases or show everything in other columns
                  )
                  .sort((a, b) => {
                    let retval = 0;
                    if (
                      sortOrder.indexOf(b.ragStatus) <
                      sortOrder.indexOf(a.ragStatus)
                    )
                      retval = -1;
                    if (
                      sortOrder.indexOf(b.ragStatus) >
                      sortOrder.indexOf(a.ragStatus)
                    )
                      retval = 1;

                    if (retval === 0)
                      retval =
                        a.TimeOfArrivalAtClinic < b.TimeOfArrivalAtClinic ||
                        !b.TimeOfArrivalAtClinic
                          ? -1
                          : 1;
                    return retval;
                  })
                  .map((d) => (
                    <Cell
                      handleUpdateCell={props.handleUpdate}
                      key={d.CaseId}
                      item={d}
                      contextMenuStatuses={props.contextMenuStatuses}
                    ></Cell>
                  ))) || <Skeleton count={3} />}
            </div>
          </Collapse>
        </div>
      </ColumnContainer>
    </Col>
  );
}
export default Column;
