import React from "react";
import { Mouse2 } from "react-bootstrap-icons";
import ButtonFilterTriaged from "../WaitingRoom/Column/ButtonFilterTriaged";
import AnouncmentBtn from "../../assets/ribbon-lg-announcement.png";
import LinksBtn from "../../assets/ribbon-lg-links.png";
import Cell from "../WaitingRoom/Cell";

const dummyStatusList = [
  {
    id: "Travelling To Clinic",
    isInContextMenu: true,
  },
  {
    id: "Awaiting Consultation",
    isInContextMenu: true,
  },
  {
    id: "In Consult",
    isInContextMenu: true,
  },
];

const dummyCase = {
  ClinicId: 54,
  CaseId: 1234567,
  CaseDate: "2020-12-22 10:07:13.243",
  TimeDueAtClinic: "2020-12-22 10:36:00.000",
  CaseStatusId: 5,
  CaseStatus: "Awaiting Consultation ",
  TimeOfArrivalAtClinic: "2019-11-25 9:00:00.000",
  CallbackRequired: 0,
  IsEmergency: 0,
  ClientNameWithTitle: "Mr Niall Phillips",
  AnimalName: "Pingu",
  Species: "Penguin",
  PresentingComplaint: "",
  Symptom: "Antarctic tourism",
  IsHomeVisit: 0,
  IsDangerous: 0,
  IsPdsa: 0,
  HasRelatedCases: 1,
  WasVideoConsult: 0,
  ragStatus: "R",
  ragCreated: 1624262611736,
  ragCreatedBy: "donnie.graham",
};

export const tourConfig = [
  {
    selector: '[data-tut="reactour__columns"]',
    content: `All Helix case statuses are split into individual collapsable columns. \n\n The pre-existing inpatient & waiting list have been combined.`,
  },
  {
    selector: '[data-tut="reactour__badge"]',
    content: `This number indicates the number of cases currently in this status`,
  },
  {
    selector: '[data-tut="reactour__cell"]',
    content: () => (
      <>
        <div>Cases can be double clicked to open the full case window.</div>
        <br />
        <div>
          Right click <Mouse2 /> on a case to quickly change its status.
        </div>
        <br />
        <div>
          Cases "Awaiting Consultation" can be triaged and assigned a RAG status
          using the right-click menu.
        </div>
        <br />
        <Cell
          handleUpdateCell={null}
          key={dummyCase.CaseId}
          item={dummyCase}
          contextMenuStatuses={dummyStatusList}
        ></Cell>
      </>
    ),
  },
  {
    selector: '[data-tut="reactour__awaiting"]',
    content: () => (
      <>
        <ButtonFilterTriaged
          triagedOnly={false}
          setTriagedOnly={null}
          notTriaged={4}
        />
        <br />
        <br />
        <div>
          The Awaiting triage button displays when there are cases awaiting
          triage.
        </div>
        <br />
        <div>
          Use the button to quickly filter this column &amp; show cases still
          awaiting triage.
        </div>
      </>
    ),
  },
  {
    selector: '[data-tut="reactour__progressBar"]',
    content: (
      <>
        <div>
          The RAG status progress bar gives you a quick breakdown of how many
          cases are in each RAG status.
        </div>
        <br />
        <div>
          Hover over the bar with your mouse for a detailed count of cases in
          each RAG status.
        </div>
      </>
    ),
  },
  {
    selector: '[data-tut="reactour__refreshBtn"]',
    content: () => (
      <>
        <div>Click the refresh button to reload the waiting room data</div>
        <div>
          <br />
          <strong>*Automatic refreshes occur every 60 seconds</strong>
        </div>
      </>
    ),
  },
  {
    selector: '[data-tut="reactour__cell"]',
    content: () => (
      <>
        <div>
          <img
            src={AnouncmentBtn}
            alt="Announcement button"
            style={{ float: "right" }}
          />
          Announcments have been moved from the homepage to the main navigation
          bar at the top of the screen.{" "}
        </div>
        <br />
        <div>
          A count of recently changed announcements will appear inside a red
          circle.
        </div>
        <br />
        <div>
          Click the "Home" button in Helix to return to this view of the waiting
          room.
        </div>
      </>
    ),
  },
  {
    selector: '[data-tut="reactour__cell"]',
    content: () => (
      <>
        <div>
          <img src={LinksBtn} alt="Links button" style={{ float: "right" }} />A
          set of useful Links has been created, access the Links menu to quickly
          access other applications.{" "}
        </div>
      </>
    ),
  },
];
