import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import Column from "./components/WaitingRoom/Column";
import ChildColumn from "./components/WaitingRoom/ChildColumn";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorToast from "./components/ErrorToast";
import useWindowSize from "./hooks/useWindowDimensions";
import useLocalStorage from "./hooks/useLocalStorage";
import useEventListener from "./hooks/useEventListener";
import GuidedTour from "./components/GuidedTour/GuidedTour";
import { QuestionCircle, ArrowClockwise } from "react-bootstrap-icons";
// import EmbeddedPowerBI from "./components/EmbeddedPowerBI";
// import { sampleData } from "./sampleData";
import { configData } from "./configData";

function App() {
  const { height, width } = useWindowSize();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [data, setData] = useState("");
  const [allClinicsSelected, setAllClinicsSelected] = useState(false);
  const [user, setUser] = useState("");
  const [error, setError] = useState("");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [columns, setColumns] = useLocalStorage("ConfigCaseStatuses", null);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    refreshConfigCaseStatuses();
    getUser();

    //TO BE REMOVED WHEN EMBEDDED IN HELIX

    // getRagStatuses();
    // setData(sampleData);

    // const intervalGetRagStatusData = setInterval(() => {
    //   getRagStatuses();
    // }, 30000);

    // END

    window.parent.postMessage({ message: "requestWaitingRoomData" }, "*");

    const intervalGetWaitingRoomData = setInterval(() => {
      setIsRefreshing(true);
      window.parent.postMessage({ message: "requestWaitingRoomData" }, "*");
    }, 60000);

    return () => {
      clearInterval(intervalGetWaitingRoomData);
      // clearInterval(intervalGetRagStatusData);
    };
  }, []);

  const messageHandler = useCallback((event) => {
    // if (
    //   !event.origin.endsWith("vets-now.com") ||
    //   !event.origin.endsWith("brightwire.net")
    // )
    //   return;
    switch (event.data.message) {
      case "waitingRoomDataResult":
        getRagStatuses(event.data.result);
        break;
      case "updateCaseStatusResult":
        if (!event.data.result) {
          setError("Case Status Update Failed");
          window.parent.postMessage({ message: "requestWaitingRoomData" }, "*");
        }
        break;
      case "waitingRoomAllClinicsSelected":
        setAllClinicsSelected(true);
        break;
      default:
    }
  });
  useEventListener("message", messageHandler);

  const getRagStatuses = async (CaseData) => {
    if (!CaseData || !CaseData.length) return;
    const result = await fetch(`/api/getWaitingRoomRagStatusData`, {
      method: "GET",
      headers: {
        Cases: JSON.stringify(CaseData.map((x) => x.CaseId)),
      },
    }).catch((e) => {
      setError(e);
    });

    let json = [];
    await result
      .json()
      .then((d) => {
        json = d;
      })
      .catch((e) => {
        setError(e);
      });
    let newArr = [...CaseData];
    json.forEach((ragStatus) => {
      //Match rag status's stored in CosmosDB to cases pushed in from Helix
      const index = newArr.findIndex((x) => x.CaseId === ragStatus.CaseId);
      newArr[index].ragStatus = ragStatus.RagStatus;
      newArr[index].ragCreated = ragStatus.Created;
      newArr[index].ragCreatedBy = ragStatus.CreatedBy;
    });
    setData(newArr);
    setTimeout(() => {
      setIsRefreshing(false);
    }, 1000);
  };

  const getUser = () => {
    setUser(params.Username);
  };

  const refreshConfigCaseStatuses = async () => {
    setColumns(configData);
    // const result = await fetch(`/api/getConfigData`, {
    //   method: "GET",
    // })
    //   .then((resp) => resp.json())
    //   .then((cols) => setColumns(cols))
    //   .catch((e) => {
    //     setError(e);
    //   });
  };
  const updateCase = async (caseId, ragStatus, caseStatus, caseStatusId) => {
    let newArr = [...data];
    const index = newArr.findIndex((x) => x.CaseId === caseId);
    if (caseStatus) {
      window.parent.postMessage(
        {
          message: "updateCaseStatus",
          CaseId: caseId,
          CaseStatusId: caseStatusId,
        },
        "*"
      );
      newArr[index].CaseStatus = caseStatus;
      newArr[index].CaseStatusId = caseStatusId;
      newArr[index].uiUpdate = true;
      if (
        caseStatus === "Awaiting Consultation" &&
        !newArr[index].TimeOfArrivalAtClinic
      )
        newArr[index].TimeOfArrivalAtClinic = new Date();
      setData(newArr);
    } else {
      newArr[index].ragStatus = ragStatus;
      newArr[index].ragCreated = Date.now();
      newArr[index].ragCreatedBy = user;
      newArr[index].uiUpdate = true;
      setData(newArr);
      const result = await fetch(`/api/postWaitingRoomCaseStatus`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CaseId: caseId,
          RagStatus: ragStatus,
          User: user,
        }),
      })
        .then((resp) => {
          if (resp.status !== 200) {
            setError(
              new Error(
                "Unable to update rag status: " +
                  resp.status +
                  " " +
                  resp.statusText
              )
            );
          }
        })
        .catch((e) => {
          setError(new Error("Unable to update rag status: " + e.message));
        });
    }
  };

  return (
    <ErrorBoundary>
      <GuidedTour
        isTourOpen={isTourOpen}
        setIsTourOpen={setIsTourOpen}
      ></GuidedTour>
      {allClinicsSelected && (
        <>
          <Alert
            variant={"warning"}
            style={{
              width: "fit-content",
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 15,
              paddingRight: 15,
              margin: 0,
              position: "fixed",
              left: 8,
            }}
          >
            You must select a Clinic from the Clinic Selector to load a waiting
            room.
          </Alert>
          <br />
          {/* <EmbeddedPowerBI /> */}
        </>
      )}
      {!allClinicsSelected && (
        <Container fluid className="mt-3">
          <div
            style={{
              position: "absolute",
              top: 2,
              right: 7,
              opacity: 0.75,
              zIndex: 9999,
            }}
          >
            <Button
              data-tut="reactour__refreshBtn"
              style={{
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 5,
                paddingRight: 5,
                marginRight: 10,
              }}
              variant="success"
              onClick={() => {
                window.parent.postMessage(
                  { message: "requestWaitingRoomData" },
                  "*"
                );
                setIsRefreshing(true);
                setTimeout(() => {
                  setIsRefreshing(false);
                }, 1000);
              }}
            >
              Refresh{" "}
              <ArrowClockwise className={isRefreshing ? "fullRotate" : ""} />
            </Button>
            {width > 991 && (
              <Button
                style={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
                onClick={() => setIsTourOpen(true)}
                className="btn-user-guide"
              >
                User Guide <QuestionCircle />
              </Button>
            )}
          </div>

          <Row>
            {columns &&
              columns
                .slice(0, 5)
                .map((c, i) => (
                  <Column
                    isFirstColumn={i === 0}
                    key={c.id}
                    title={c.id}
                    data={
                      data && data.filter((d) => d.CaseStatus.trim() === c.id)
                    }
                    handleUpdate={updateCase}
                    contextMenuStatuses={columns.filter(
                      (x) => x.isInContextMenu
                    )}
                    width={width}
                  ></Column>
                ))}
            <Col className="p-2" sm="6" lg="2" xl="2">
              <div
                style={{
                  maxHeight: width > 576 ? "90vh" : "none",
                  overflowX: "auto",
                  position: "relative",
                }}
              >
                {columns &&
                  columns
                    .slice(5, columns.length + 1)
                    .map((cc) => (
                      <ChildColumn
                        openDefault={cc.id === "Home Visit" || false}
                        key={cc.id}
                        title={cc.id}
                        data={
                          data && data.filter((d) => d.CaseStatus === cc.id)
                        }
                        contextMenuStatuses={columns.filter(
                          (x) => x.isInContextMenu
                        )}
                        handleUpdate={updateCase}
                        width={width}
                      ></ChildColumn>
                    ))}
              </div>
            </Col>
          </Row>
          <ErrorToast error={error}></ErrorToast>
        </Container>
      )}
    </ErrorBoundary>
  );
}

export default App;
