import React from "react";
import { Button, Badge } from "react-bootstrap";
import { Filter } from "react-bootstrap-icons";
import styled from "styled-components";

const TextContainer = styled.div`
  font-size: 12px;
  .badge {
    right: -6px;
    top: 2px;
  }
  @media (min-width: 1159px) {
    font-size: 14px;
    .badge {
      right: initial;
      top: initial;
    }
  }
`;

function ButtonFilterTriaged(props) {
  return (
    <Button
      style={{ width: "97%" }}
      className={"mx-auto filter-triaged"}
      variant={props.triagedOnly ? "secondary" : "warning"}
      onClick={() => props.setTriagedOnly(!props.triagedOnly)}
      title={props.triagedOnly ? "Show all" : "Show only cases not yet triaged"}
    >
      <TextContainer>
        <strong>Awaiting triage </strong>{" "}
        <Filter
          className={props.triagedOnly ? "rotate" : "un-rotate"}
          size={22}
        />
        <Badge style={{ fontSize: 14 }} variant="light" className="float-right">
          {props.notTriaged}
        </Badge>
      </TextContainer>
    </Button>
  );
}
export default ButtonFilterTriaged;
