import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { CircleFill, PencilSquare } from "react-bootstrap-icons";
import { ContextMenu, MenuItem } from "react-contextmenu";

function Contextmenu(props) {
  const handleClick = (e, data) => {
    props.handleUpdateContext(
      data.CaseId,
      data.RagStatus,
      data.CaseStatus,
      data.CaseStatusId
    );
  };

  return (
    <div>
      <ContextMenu id={props.CaseId + "-contextmenu"}>
        {props.showRag && (
          <>
            <MenuItem
              data={{ RagStatus: "R", CaseId: props.CaseId, CaseStatus: false }}
              onClick={handleClick}
            >
              <Row>
                <Col xs="8">Set to Red</Col>
                <Col>
                  <CircleFill color="#F44336" />
                </Col>
              </Row>
            </MenuItem>
            <MenuItem
              data={{ RagStatus: "A", CaseId: props.CaseId, CaseStatus: false }}
              onClick={handleClick}
            >
              <Row>
                <Col xs="8">Set to Amber</Col>
                <Col>
                  <CircleFill color="#F68D00" />
                </Col>
              </Row>
            </MenuItem>
            <MenuItem
              data={{ RagStatus: "G", CaseId: props.CaseId, CaseStatus: false }}
              onClick={handleClick}
            >
              <Row>
                <Col xs="8">Set to Green</Col>
                <Col>
                  <CircleFill color="#29CA17" />
                </Col>
              </Row>
            </MenuItem>
            <MenuItem
              data={{ RagStatus: "", CaseId: props.CaseId, CaseStatus: false }}
              onClick={handleClick}
            >
              <Row>
                <Col xs="8">Clear RAG</Col>
                <Col>
                  <CircleFill color="#ced1d2" />
                </Col>
              </Row>
            </MenuItem>
            <MenuItem divider />{" "}
          </>
        )}

        {props.caseStatusList &&
          props.caseStatusList
            .filter((d) => d.id !== props.CurrentStatus.trim())
            .map((x) => (
              <MenuItem
                onClick={handleClick}
                data={{
                  RagStatus: "",
                  CaseId: props.CaseId,
                  CaseStatus: x.id,
                  CaseStatusId: x.CaseStatusId,
                }}
              >
                <Row>
                  <Col xs="10">{x.id}</Col>
                  <Col xs="1">
                    <PencilSquare />
                  </Col>
                </Row>
              </MenuItem>
            ))}
      </ContextMenu>
    </div>
  );
}
export default Contextmenu;
