import React, { useState, useEffect } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { tourConfig } from "./TourConfig";
import Tour from "reactour";
import {Button} from "react-bootstrap";
import {EmojiSmile} from "react-bootstrap-icons";

function GuidedTour(props) {
  const [isShowingMore, setIsShowingMore] = useState(false);
  useEffect(() => {}, []);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = "#5cb7b7";

  return (
    <Tour
      onRequestClose={() => props.setIsTourOpen(false)}
      steps={tourConfig}
      isOpen={props.isTourOpen}
      maskClassName="mask"
      className="helper"
      rounded={5}
      accentColor={accentColor}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      lastStepNextButton={<Button>Finish <EmojiSmile /></Button>} 
      startAt={0}
    ></Tour>
  );
}
export default GuidedTour;
