import React, { useState, useEffect } from "react";
import { Toast } from "react-bootstrap";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          {this.props.children}
          <Toast
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            show={this.state.hasError}
            onClose={() => this.setState({ hasError: false })}
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong className="mr-auto">Error</strong>
            </Toast.Header>
            <Toast.Body>An issue has occured</Toast.Body>
          </Toast>
        </>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
