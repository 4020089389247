import React from "react";

export const ragStatusColor = (status) => {
  switch (status) {
    case "R":
      return "#F44336";
    case "A":
      return "#F68D00";
    case "G":
      return "#29CA17";
    default:
      return "#ced1d2";
  }
};

export const isToday = (date) => {
  const today = new Date();
  const newDate = new Date(date);
  return (
    newDate.getDate() === today.getDate() &&
    newDate.getMonth() === today.getMonth() &&
    newDate.getFullYear() === today.getFullYear()
  );
};

export const timeLabel = (props) => {
  if (props.TimeOfArrivalAtClinic) {
    return (
      <>
        Arrived:{" "}
        {isToday(props.TimeOfArrivalAtClinic)
          ? `Today, ${new Date(
              props.TimeOfArrivalAtClinic
            ).toLocaleTimeString()}`
          : new Date(props.TimeOfArrivalAtClinic).toLocaleString()}
      </>
    );
  } else if (props.TimeDueAtClinic) {
    return (
      <>
        Due:{" "}
        {isToday(props.TimeDueAtClinic)
          ? `Today, ${new Date(props.TimeDueAtClinic).toLocaleTimeString()}`
          : new Date(props.TimeDueAtClinic).toLocaleString()}
      </>
    );
  } else {
    return "Due: Unknown";
  }
};

export const percentage = (partialVal, totalVal) =>
  (100 * partialVal) / totalVal;
