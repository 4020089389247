import React, { useState, useEffect } from "react";
import { Toast } from "react-bootstrap";

function ErrorToast(props) {
  const [error, setError] = useState("");

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  return (
    <Toast
      className={error ? "toast-show" : ""}
      // delay={3000}
      // autohide
      show={error !== ""}
      onClose={() => setError("")}
    >
      <Toast.Header>
        <strong className="mr-auto">Error</strong>
      </Toast.Header>
      <Toast.Body>{error.message}</Toast.Body>
    </Toast>
  );
}
export default ErrorToast;
