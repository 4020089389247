export const configData = [
  {
    id: "Travelling To Clinic",
    SortOrder: 1,
    isInContextMenu: true,
    CaseStatusId: 1,
    _ts: 1627543125,
  },
  {
    id: "Awaiting Consultation",
    SortOrder: 2,
    isInContextMenu: true,
    CaseStatusId: 5,
    _ts: 1627543125,
  },
  {
    id: "In Consult",
    SortOrder: 3,
    isInContextMenu: true,
    CaseStatusId: 2,
    _ts: 1627543125,
  },
  {
    id: "Vet Callback Required",
    SortOrder: 4,
    isInContextMenu: true,
    CaseStatusId: 15,
    _ts: 1627543125,
  },
  {
    id: "Admitted",
    SortOrder: 5,
    isInContextMenu: true,
    CaseStatusId: 3,
    _ts: 1627543125,
  },
  {
    id: "Home Visit",
    SortOrder: 6,
    isInContextMenu: true,
    CaseStatusId: 11,
    _ts: 1627543125,
  },
  {
    id: "Journal Finished",
    SortOrder: 7,
    isInContextMenu: true,
    CaseStatusId: 7,
    _ts: 1627543125,
  },
  {
    id: "Video Vets Now",
    SortOrder: 8,
    isInContextMenu: false,
    _ts: 1627543125,
  },
  {
    id: "Not In Clinic",
    SortOrder: 9,
    isInContextMenu: true,
    CaseStatusId: 4,
    _ts: 1627543125,
  },
  {
    id: "Vet Check Required",
    SortOrder: 10,
    isInContextMenu: false,
    CaseStatusId: 17,
    _ts: 1627543125,
  },
  {
    id: "TTC Callback Required",
    SortOrder: 11,
    isInContextMenu: false,
    CaseStatusId: 16,
    _ts: 1627543125,
  },
  {
    id: "Awaiting Appointment",
    SortOrder: 12,
    isInContextMenu: false,
    CaseStatusId: 14,
    _ts: 1627543125,
  },
  {
    id: "External Referral",
    SortOrder: 13,
    isInContextMenu: false,
    CaseStatusId: 12,
    _ts: 1631259058,
  },
  {
    id: "Awaiting Discharge",
    SortOrder: 14,
    isInContextMenu: true,
    CaseStatusId: 19,
    _ts: 1633619527,
  },
  {
    id: "Ready For Collection",
    SortOrder: 15,
    isInContextMenu: false,
    CaseStatusId: 18,
    _ts: 1643124295,
  },
];
